import React, { useEffect, useState } from "react"; //, useState
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UploadModal from "./UploadModal";
import LoadingCarouselSlideup from "./dummyElements/LoadingCarouselSlideup";
import * as Sentry from "@sentry/react";


const url =
  "https://3xxvpukvn67wzjtkapupqin4d40ebikh.lambda-url.us-east-1.on.aws/";



  
const LoadingPage: React.FC = () => {
  const location = useLocation();
  const { myData } = location.state || {}; // Fallback to an empty object if state is undefined
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [tryerr, setTryErr] = useState(false);
  const [isresume, setIsResume] = useState(false);
  //const[initdata, setInitdata] = useState({});
 // let trial = "";
 // const [applicantData, setApplicantData] = useState(null);

 

  let json_data = "";

  const headers = {
    "Content-Type": "application/json",
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const generate = () => {
    // console.log('generate run');
    //setProgressvisible(true);
    setTryErr(false);

    //setUploadPercentage(0);

    //sessionStorage.setItem('func_call', 'true');

    // console.log("fetch");
    // console.log("fetch1  ", myData);
    console.log('resume init1  ',myData.resume);
    // console.log(myData.resume.split("   "));

    // const phonenumber = findPhoneNumbersInText(myData.resume);
    // const emailId = findEmailID(myData.resume);

    // console.log("see number  ", phonenumber);
    // console.log("see email  ", emailId);

    axios({
      method: "post",
      url: url,
      data: {
        resume: myData.resume,
        auth_key: "asdfglkjh",
        question: "init_data",
        yoe: "10",
        position: "frontend developer",
      },
      headers: headers
    })
      .then(function (response) {
        console.log('respone init_data:-  ',response);
        // console.log("data111");
        // console.log(response.data);
        // console.log("data11  ", response.data.choices[0].message.content);
        // eslint-disable-next-line prefer-const
        //let x = JSON.parse(response.data.choices[0].text);

        if (response.data.choices[0].text == undefined) {
          //console.log("x111 ", response.data.choices[0].message.content);
          json_data = response.data.choices[0].message.content;
        } else {
          //console.log("x222 ", response.data.choices[0].text);
          json_data = response.data.choices[0].text;
        }

        

        // console.log("json_data2  ", json_data);

        // json_data = json_data.replace(/'/g, '"');

        // console.log("json_data3  ", json_data);

        //json_data = json_data.replace('?', '');

        //const json_datas = JSON.parse(json_data);

        //const jsonndata2 = cleanKeys(json_datas);

        

        //console.log("json_data44 ", json_data);

        const jsondata1 = JSON.parse(json_data); 

        //const jsondata2 = replaceQuestionMarks(jsondata1);

        //console.log("json_data  ", json_data);

       // console.log("json_data1 ", jsondata1);
        //setInitdata(jsondata1);
       //getTries(jsondata1)

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
       //console.log('name  ', jsondata1.NameOfApplicant.toLowerCase());
       //console.log('Email  ', jsondata1.Email.toLowerCase());
      //  console.log('IsTheDocResume  ', jsondata1.IsTheDocResume);
        
        
        
      if(jsondata1.NameOfApplicant != null && jsondata1.Email != null){

        check_resume(jsondata1);
      }
      else{
        setIsOpen(true);
        setIsResume(true);
      }
        

        //setUploadPercentage(0);

        //setProgressvisible(false);
        //console.log(x.Name);
      })
      .catch(function (error) {
        //console.log("init_data error");
        setIsOpen(true);
        console.log(error);
        //setUploadPercentage(0);
        // setErrorMessage("Some issue occured while scaning the data");
        // setProgressvisible(false);
        // setFile(null);
      });

    //console.log("fetch end");
  };

  // const findPhoneNumbersInText = (text) => {  /// phone extract
  //   const phoneNumberPattern =
  //     /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/g;
  //   //const regex = /(\+91[-\s]?)?[0]?(91)?[789]\d{9}/g;
  //   return text.match(phoneNumberPattern);
  // };

  // const findEmailID = (text) => {   email extract
  //   const regex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

  //   //const regex = /(\+91[-\s]?)?[0]?(91)?[789]\d{9}/g;
  //   return text.match(regex);
  // };

  const clean_version_new = async (resumetext: string, tries) => {
    //console.log("hit new");
    console.log('payload clean_grammatical:- ', resumetext)
    // console.log('payload clean_grammatical tries:- ', tries)
    await axios({
      method: "post",
      url: url,
      data: {
        resume: resumetext,
        auth_key: "asdfglkjh",

        question: "structured_resume",//"clean_grammatical",
        yoe: "10",
        position: "frontend developer",
      },
      headers: headers
    })
      .then(function (response) {
       // console.log("clean");
        //console.log(response);
        //console.log('resp json clean_grammatical:-  ',response);
        console.log('respone clean_grammatical:-  ',response);

        let cleanresume = "";

        if (response.data.choices[0].text == undefined) {
          //console.log("clean1 ", response.data.choices[0].message.content);
          cleanresume = response.data.choices[0].message.content;
        } else {
          //console.log("clean2 ", response.data.choices[0].text);
          cleanresume = response.data.choices[0].text;
        }

        //console.log("wordcount   ", countWords(cleanresume));

        // navigate('/report_new', { state: { myData: {
        //   employedetail: json_data,
        //   Yoe: myData.Yoe,
        //   JobRole : myData.JobRole,
        //   JobStatus: true,
        //   cleanversion: cleanresume
        //   } } });

        Promise.all([
          possible_position(cleanresume),
          relevant_resume(cleanresume),
          skill_gap_analysis(cleanresume),
          cover_letter(cleanresume)
        ])
          .then((results) => {
            const [
              possible_position,
              relevant_resume,
              skill_gap_analysis,
              cover_letter
            ] = results;
            // console.log("possible_position:", possible_position);
            // console.log("relevant_resume:", relevant_resume);
            // console.log("skill_gap_analysis  ", skill_gap_analysis);
            // console.log("cover_letter  ", cover_letter);
            console.log("results  ", results);
            //console.log("trial1  ", Tries);

            if (
              possible_position != undefined &&
              relevant_resume != undefined &&
              skill_gap_analysis != undefined &&
              cover_letter != undefined
            ) {
              navigate("/report_new", {
                state: {
                  myData: {
                    employedetail: json_data,
                    Yoe: myData.Yoe,
                    JobRole: myData.JobRole,
                    JobDesc:myData.JobDesc,
                    JobStatus: true,
                    cleanversion: cleanresume,
                    PossiblePosition: possible_position,
                    RelevantResume: relevant_resume,
                    SkillGap: skill_gap_analysis,
                    CoverLetter: cover_letter,
                    image: myData.image,
                    pageno: myData.pageno,
                    resumetype: myData.resumetype,
                    resumelength: countWords(cleanresume),
                    filesize: myData.filesize,
                    plain_resume: myData.resume,
                    trial: tries
                  },
                },
              });
            }
          })
          .catch((error) => {
            console.error("Failed to fetch data:", error);
          });
      })
      .catch(function (error) {
       // console.log("clean_grammatical2 error");
        console.log("clean_grammatical2 error ",error);
        setIsOpen(true);
        sessionStorage.removeItem("alreadyRefreshed");
      });
  };

  // const data_store = async (data) => {
  //   //console.log('data store start');
  //   console.log('data store ',data);
  //   let header: {
  //     "Access-Control-Allow-Origin": "http://localhost:3000/";
  //     "Access-Control-Allow-Methods": "OPTIONS,POST,GET";
  //     "Access-Control-Allow-Headers": "Content-Type, Authorization";
  //   };

  //   const urls =
  //     "https://wo2xhu0oe3.execute-api.ap-south-1.amazonaws.com/uat/applicant";

  //   await axios({
  //     method: "post",
  //     url: urls,
  //     data: data,
  //     headers: header,
  //   })
  //     .then(function (response) {
  //       //console.log("upload done");
  //       console.log('respone data_store:-  ',response);
  //     })
  //     .catch(function (error) {
  //       //console.log("new error2");
  //       //setIsOpen(true);
  //       console.log(error);
  //     });
  // };

  /*
  const clean_version = async(resumetext: string) => {
    console.log('hit');
    await axios({
      method: 'post',
      url: url,
      data: {
        resume:resumetext,
        auth_key:"asdfglkjh",
        
        question: 'clean_version',
        yoe: '10',
        position: 'frontend developer'
        },
      headers : headers,
      onDownloadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0));
        //setUploadPercentage(Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0)));
        console.log('perc1')
      console.log(percentCompleted)

        }
    })
    .then(function (response) {
      console.log('clean');
      console.log(response);
    
     let cleanresume = "";

      if (response.data.choices[0].text == undefined){
        console.log('clean1 ',response.data.choices[0].message.content);
        cleanresume = response.data.choices[0].message.content;
      }
      else{
        console.log('clean2 ',response.data.choices[0].text);
        cleanresume = response.data.choices[0].text;
      }

      
      //const dataset = cleanresume.split('\n\n');
      ///console.log("json_data2  ", user_data);
     

      grammify(cleanresume);

      // navigate('/report', { state: { myData: {
      //   employedetail: user_data,
      //   Yoe: myData.Yoe,
      //   JobRole : myData.JobRole,
      //   JobStatus: true,
      //   cleanversion: cleanresume
      //   } } });

        sessionStorage.removeItem('alreadyRefreshed');
      
    })
    .catch(function (error) {
      console.log("new error2");
      console.log(error);
      
    });
  }


  const grammify = (cleanversion: string) => {
    const dataset = cleanversion.split('\n\n');
    console.log("dataset1  ", dataset);


    const promises = dataset.filter(dataset => dataset.length > 0).map(dataset => grammification(dataset));

    console.log('promise   ',promises);

    Promise.all(promises)
      .then(data => {
        // data is an array of responses from each URL
        console.log('vachan  ',data);
        const finaldata = data.join('\n\n');
        console.log('finaldata  ',finaldata);

        navigate('/report', { state: { myData: {
        employedetail: json_data,
        Yoe: myData.Yoe,
        JobRole : myData.JobRole,
        JobStatus: true,
        cleanversion: finaldata
        } } });

      }) 
      .catch(error => {
        console.error('Failed to fetch:', error);
      });
      

  }



  const grammification = async(sentence) => {
    console.log("grammered1  ", sentence);

     return await axios({
      method: 'post',
      url: url,
      data: {
        auth_key:"asdfglkjh",
        resume:sentence,
        question: 'grammatical',
        yoe: '10',
        position: 'frontend developer'
        },
      headers : headers
    })
    .then(function (response) {
      //console.log('clean');
      //console.log(response);
      
    
     let cleanresume = "";

      if (response.data.choices[0].text == undefined){
        console.log('gram1 ',response.data.choices[0].message.content);
        cleanresume = response.data.choices[0].message.content;
      }
      else{
        console.log('gram2 ',response.data.choices[0].text);
        cleanresume = response.data.choices[0].text;
      }

      //console.log("grammered1  ", sentence);
      console.log("grammered  ", cleanresume);

      return cleanresume;

      

      
      
    })
    .catch(function (error) {
      console.log("gram error2");
      console.log(error);
      
    });

  } */

  const possible_position = async (clean_resume) => {
    // console.log("click1!  ", {
    //   auth_key: "asdfglkjh",
    //   resume: clean_resume,
    //   question: "possible_position",
    //   yoe: myData.Yoe,
    //   position: myData.JobRole,
    // });
   // console.log('payload possible_position:-',clean_resume);

    return await axios({
      method: "post",
      url: url,
      data: {
        auth_key: "asdfglkjh",
        resume: clean_resume,
        question: "possible_position",
        yoe: myData.Yoe,
        position: myData.JobRole,
      },
      headers: headers,
    })
      .then(function (response) {
        //console.log("possible  ", response.data.choices[0].text);
        //console.log('resp json possible_position:-  ',response);
        //return response.data.choices[0].text;
        //let x = JSON.parse(response.data.choices[0].text);
        console.log('respone possible_position:-  ',response);
        let cleanresume = "";

        if (response.data.choices[0].text == undefined) {
          //console.log("clean1 ", response.data.choices[0].message.content);
          cleanresume = response.data.choices[0].message.content;
        } else {
          //console.log("clean2 ", response.data.choices[0].text);
          cleanresume = response.data.choices[0].text;
        }

        return cleanresume;
      })
      .catch(function (error) {
        setIsOpen(true);
        console.log('possible_position error',error);
      });
  };

  const relevant_resume = async (clean_resume) => {
    //console.log("click2!");
    //console.log('payload relevant_resume:-',clean_resume);
    return await axios({
      method: "post",
      url: url,
      data: {
        auth_key: "asdfglkjh",
        resume: clean_resume,
        question: "relevant_resume",
        yoe: myData.Yoe,
        position: myData.JobRole,
      },
      headers: headers,
    })
      .then(function (response) {
        // console.log("relevant  ", response.data.choices[0].text);
        // console.log('resp json relevant_resume:-  ',response);
        //return response.data.choices[0].text;
        
        console.log('respone relevant_resume:-  ',response);
        let cleanresume = "";

        if (response.data.choices[0].text == undefined) {
          //console.log("clean1 ", response.data.choices[0].message.content);
          cleanresume = response.data.choices[0].message.content;
        } else {
          //console.log("clean2 ", response.data.choices[0].text);
          cleanresume = response.data.choices[0].text;
        }

        return cleanresume;

        //let x = JSON.parse(response.data.choices[0].text);
      })
      .catch(function (error) {
        setIsOpen(true);
        console.log('relevant_resume ',error);
      });
  };

  const skill_gap_analysis = async (clean_resume) => {
   // console.log("click3!  ", myData.Yoe);
   //console.log('payload upskill:-',clean_resume);

    return await axios({
      method: "post",
      url: url,
      data: {
        auth_key: "asdfglkjh",
        resume: clean_resume,
        question: "upskill",
        yoe: myData.Yoe,
        position: myData.JobRole,
      },
      headers: headers,
    })
      .then(function (response) {
        // console.log(response.data.choices[0].text);
        // console.log('resp json upskill:-  ',response);
        //return response.data.choices[0].text;
        console.log('respone upskill:-  ',response);
        let cleanresume = "";

        if (response.data.choices[0].text == undefined) {
          //console.log("clean1 ", response.data.choices[0].message.content);
          cleanresume = response.data.choices[0].message.content;
        } else {
          //console.log("clean2 ", response.data.choices[0].text);
          cleanresume = response.data.choices[0].text;
        }

        return cleanresume;

        //let x = JSON.parse(response.data.choices[0].text);
      })
      .catch(function (error) {
        setIsOpen(true);
        console.log('upskill ',error);
      });
  };

  // const bias_view = async (clean_resume) => {
  //   console.log("click4!");

  //   axios({
  //     method: "post",
  //     url: url,
  //     data: {
  //       auth_key: "asdfglkjh",
  //       resume: clean_resume,
  //       question: "bias_view",
  //       yoe: myData.Yoe,
  //       position: myData.JobRolebrole
  //     },
  //     headers: headers,
  //   })
  //     .then(function (response) {
  //       console.log(response.data.choices[0].text);

  //       //let x = JSON.parse(response.data.choices[0].text);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // const mandatory = async (clean_resume) => {
  //   console.log("click5!");

  //   axios({
  //     method: "post",
  //     url: url,
  //     data: {
  //       auth_key: "asdfglkjh",
  //       resume: clean_resume,
  //       question: "mandatory",
  //       yoe: myData.Yoe,
  //       position: myData.JobRolebrole
  //     },
  //     headers: headers,
  //   })
  //     .then(function (response) {
  //       console.log(response.data.choices[0].text);

  //       //let x = JSON.parse(response.data.choices[0].text);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const cover_letter = async (clean_resume) => {
   // console.log("click6!");
   //console.log('payload cover_letter:-',clean_resume);

    return await axios({
      method: "post",
      url: url,
      data: {
        auth_key: "asdfglkjh",
        resume: clean_resume,
        question: "cover_letter",
        yoe: myData.Yoe,
        position: myData.JobRole,
        company: myData.CompanyName,
      },
      headers: headers,
    })
      .then(function (response) {
        console.log('respone cover_letter:-  ',response);
        // console.log("cover  ", response);
        // console.log('resp json cover_letter:-  ',response);
        //return response.data.choices[0].text;
        let cleanresume = "";

        if (response.data.choices[0].text == undefined) {
          //console.log("clean1 ", response.data.choices[0].message.content);
          cleanresume = response.data.choices[0].message.content;
        } else {
          //console.log("clean2 ", response.data.choices[0].text);
          cleanresume = response.data.choices[0].text;
        }

        return cleanresume;

        //let x = JSON.parse(response.data.choices[0].text);
      })
      .catch(function (error) {
        setIsOpen(true);
        console.log('cover_letter  ',error);
      });
  };

  // const getTries = async(jsondata1) => {
  //   //console.log('payload getTries:- ',jsondata1);
  //   const config = {
  //     method: 'get',
  //     url: `https://wo2xhu0oe3.execute-api.ap-south-1.amazonaws.com/uat/applicant?email=${jsondata1.Email}`    
  //   };
    
  //   return await axios.request(config)
  //   .then((response) => {
  //     console.log('respone get_tries:-  ',response);
  //     // console.log('sucess try');
  //     // console.log('tries  ',response);
  //     let final_try = "";
  //     if(response.data.msg === "no data"){
  //         final_try = "4";
  //     }
  //     else{
  //       final_try = response.data.try;
  //     }

  //     if(final_try === "0"){
  //       //console.log("zero available")
  //       setIsOpen(true);
  //       setTryErr(true);
  //       return;
  //     }
      
  //     const trials = String(Number(final_try) - 1);
  //     //setTrial(trials);

  //     const data = {
  //       name: jsondata1.NameOfApplicant,
  //       applicant_type: myData.JobDesc,
  //       job_profile: myData.JobRole,
  //       yoe: myData.Yoe,
  //       phone_number: String(jsondata1.MobileNumber),
  //       email: jsondata1.Email,
  //       city: jsondata1.City,
  //       state: jsondata1.State,
  //       try: trials
  //     };

  //     //data_store(data);

  //     //console.log(" saving data ", data);

  //     return trials

  //   })
  //   .catch((error) => {
  //     //console.log('fail try');
  //     console.log('data store err',error);
  //   });
  // }


  const check_resume = (userdata) => {
    // console.log('payload is_resume: ', userdata);
    // console.log('payload is_resume plain resume: ', myData.resume);
    axios({
      method: "post",
      url: url,
      data: {
        resume: myData.resume,
        auth_key: "asdfglkjh",
        question: "is_resume",
        yoe: "10",
        position: "frontend developer",
      },
      headers: headers
    })
      .then(function (response) {
        console.log('respone is_resume:-  ',response);
        let check_data = "";
        console.log('token see', response);
        if (response.data.choices[0].text == undefined) {
          //console.log("x111 ", response.data.choices[0].message.content);
          console.log("response is_resume:- ", response);
          check_data = response.data.choices[0].message.content;
        } else {
          //console.log("x222 ", response.data.choices[0].text);
          check_data = response.data.choices[0].text;
        }

        //throw new Error('getting is resume1');

        const jsondata1 = JSON.parse(check_data);

        // console.log('IsTheDocResume1  ', jsondata1);
        // console.log('IsTheDocResume  ', jsondata1.Resume);

        if((userdata.NameOfApplicant.toLowerCase() != "null" && userdata.Email.toLowerCase() != "null") || jsondata1.Resume){
          // Promise.all([
          //   getTries(userdata)
          // ])
          //   .then((results) => {
          //     console.log('promise try  ',results[0]);
          //     // console.log('promise try1  ',String(Number(results[0])-1));
          //     if(results[0] != undefined){
          //       clean_version_new(myData.resume, results[0]);
          //     }
          //     else{
          //       setIsOpen(true);
          //     }
          //     //clean_version_new(myData.resume, results[0]);
               
          //   })
          //   .catch((error) => {
          //     console.error("Failed to fetch data:", error);
          //   });

          clean_version_new(myData.resume, 4);
        }
        else{
          setIsOpen(true);
          setIsResume(true);
        }
        
       
        
      })
      .catch(function (error) {
        console.log("is_resume error");
        setIsOpen(true);
        console.log(error);
        Sentry.captureException(error); // Logging the error to Sentry
        
      });

  }

  useEffect(() => {
    //console.log('running again');
    // Use sessionStorage to ensure the page only refreshes once per session
    
    localStorage.removeItem("hasReloaded");
    if (sessionStorage.getItem("alreadyRefreshed") !== "true") {
      sessionStorage.setItem("alreadyRefreshed", "true"); // Set the flag
      generate();
       
      
     /// window.location.reload(); // Refresh the page
      
    }

    // No cleanup function is necessary since sessionStorage is cleared automatically when the session ends
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const countWords = (text) => {
    //// to count number of words
    // Split text into words based on spaces and filter out empty strings
    return text.split(/\s+/).filter(Boolean).length;
  };

  
  

  return (
    <>
      <UploadModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="ml-1/2 justify-between items-center  overflow-hidden bg-white text-black text-[1.2rem]">
        {
        (tryerr)?(
          <p className="z-1000">
            You have exhausted your limit for today please try again tommorow.
          </p>
        ):((isresume)?(
          <p className="z-1000">
          Your resume appears to be invalid. Please upload a correct resume and try again in sometime.
        </p>
        ):(<p className="z-1000">
          Some issue occured while evaluating your resume. please reupload it
          again. Thanks.
        </p>))}
        <button
         className="pt-2 pb-2 pl-5 pr-5 text-lg bg-CTAbuttonPink rounded-lg text-white tracking-wider border-none outline-none active:from-CTAbuttonPink max-lg:text-[1rem]"
          type="button"
          style={{ transition: "all .15s ease" }}
          onClick={() => {
            navigate("/");
          }}
        >
          Go Back
        </button>
        </div>
      </UploadModal>
      <LoadingCarouselSlideup />
    </>
  );
};

export default LoadingPage;
